import { setUserInformation, setUserIsLoading } from './user.slice';
import { errorAlert } from '../alert/alert.slice';
import { setDataIsFetch } from '../data/data.slice';
import { getData } from '../data/data.thunks';
import { http } from '../../../helpers/http';
import { setLogsSelectContent, setUserPropertyId, setUserPropertyPartner } from '../../../helpers/analytics';

function dispatchError(dispatch, errorText = 'Erreur lors de la connexion') {
  dispatch(setUserInformation(null));
  dispatch(setDataIsFetch());
  dispatch(errorAlert(errorText));
}

export const reloadUser = (handle, fromLoginAction = false) => async (dispatch) => {
  dispatch(setUserIsLoading(true));
  const URL = `mobilerequestpartner`;

  try {
    const params = {method: 'POST', headers: { referer: 'unsafe-url' }, 'Content-Type': 'application/json', body:
          JSON.stringify({
            key: handle
          })}
    const data = await http(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_API_BASE_URL}/${URL}`, 'json',params,true);
    if (data) {
      if (data === 'Is in maintenance') {
        // Not authenticated
        dispatchError(dispatch,'Erreur lors de la connexion, le site est en maintenance');
        return;
      }

      if (Object.keys(data).length === 1 && data.salesman === null) {
        // Not authenticated
        dispatchError(dispatch,'Erreur lors de la connexion');
        return;
      }
      dispatch(setUserInformation(data));
      setUserPropertyId(handle);
      setUserPropertyPartner(data.partner.company_name);
      if (fromLoginAction) {
        setLogsValidateAuth();
      }
      return await dispatch(getData(handle));
    }
  } catch (err) {
    dispatch(setUserInformation(null));
    dispatch(errorAlert('Erreur lors de la connexion'));
  } finally {
    dispatch(setUserIsLoading(false));
  }
};

export const authenticateUser = (handle, captchaToken) => async (dispatch) => {
  dispatch(setUserIsLoading(true));
  const URL = `domosimuwebrequestpartneraction`;
  const params = {method: 'POST', headers: { referer: 'unsafe-url' }, 'Content-Type': 'application/json', body:
        JSON.stringify({
          key: handle ,
          captchaToken:captchaToken
        })}
  try {
    const data = await http(`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_API_BASE_URL}/${URL}`, 'json', params,true);
    if (data) {
      if (data === 'Is in maintenance') {
        // Not authenticated
        dispatchError(dispatch,'Erreur lors de la connexion, le site est en maintenance');
        return;
      }

      if (Object.keys(data).length === 1 && data.salesman === null) {
        // Not authenticated
        dispatchError(dispatch,'Erreur lors de la connexion');
        return;
      }
      dispatch(setUserInformation(data));
      setUserPropertyId(handle);
      setUserPropertyPartner(data.partner.company_name);
      setLogsValidateAuth();

      return await dispatch(getData(handle));
    }
  } catch (err) {
    dispatch(setUserInformation(null));
    dispatch(errorAlert('Erreur lors de la connexion'));
  } finally {
    dispatch(setUserIsLoading(false));
  }
};

const setLogsValidateAuth = () => {
  setLogsSelectContent('connexion_validation', 'connexion', 'login');
};
