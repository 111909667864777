import {
  setDataJson,
  setDataIsLoading,
  setParameters,
  setSimulatorFileLink,
  setFileIsLoading,
} from './data.slice';
import { errorAlert, successAlert, enableButtonActionAlert } from '../alert/alert.slice';
import { http } from '../../../helpers/http';

const GlobalBaseUrl = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_API_BASE_URL}`;

export const getData = (handle) => async (dispatch) => {
  dispatch(setDataIsLoading(true));
  const URL = `data`;
  try {
    const params = {method: 'POST', headers: { referer: 'unsafe-url' }, 'Content-Type': 'application/json', body:
          JSON.stringify({
            approvalNumber: handle
          })}
    const data = await http(`${GlobalBaseUrl}/${URL}`, 'json', params,true);
    if (data) {
      dispatch(setDataJson(data));
      dispatch(setDataIsLoading(false));
    }
  } catch (err) {
    setDataJson(null);
    dispatch(setDataIsLoading(false));
  }
};

export const loadParameters = () => async (dispatch) => {
  if (!window.navigator.onLine) {
    return;
  }

  dispatch(setDataIsLoading(true));
  try {
    const data = await http(`${GlobalBaseUrl}/parameters`, 'json',{},true);
    if (data && data.data.parameters) {
      dispatch(setParameters(data.data.parameters[0]));
      dispatch(setDataIsLoading(false));
    }
  } catch (err) {
    dispatch(setDataIsLoading(false));
  }
};

export const sendMailPdf = (id, email) => async (dispatch) => {
  dispatch(enableButtonActionAlert());
  try {
    const data = new FormData();
    data.append( "id", parseInt(id,10));
    data.append( "email", email);
    const params = {
      method: 'POST',
      body: data,
    };

    const status = await http(`${GlobalBaseUrl}/info/pdf/send`, '', params);
    if (status === 200) {
      dispatch(successAlert(`Le fichier a bien été envoyé à l'adresse ${email}.`));
    } else {
      dispatch(errorAlert('Erreur lors de l\'envoi du fichier.'));
    }
  } catch (err) {
    dispatch(errorAlert('Erreur lors de l\'envoi du fichier.'));
  }
};

export const generateFipen = (params) => async (dispatch) => {
  dispatch(enableButtonActionAlert());
  dispatch(setFileIsLoading(true));
  try {
    const queryParams = parseParams(params);
    const url = `ws_create_fipen`;
    const headerParams = {method: 'POST', headers: { referer: 'unsafe-url' }, 'Content-Type': 'application/json', body:
          queryParams}
    const data = await http(`${GlobalBaseUrl}/${url}`, 'json', headerParams,true);
    if (data.link) {
      dispatch(setSimulatorFileLink(data.link));
    } else {
      dispatch(successAlert('La fiche Fipen a bien été envoyée par email.'));
    }
  } catch (err) {
    dispatch(errorAlert('Erreur lors de la génération de la Fipen.'));
  } finally {
    dispatch(setFileIsLoading(false));
  }
};

export const generateFicom = (params) => async (dispatch) => {
  dispatch(enableButtonActionAlert());
  dispatch(setFileIsLoading(true));
  try {
    const queryParams = parseParams(params);
    const headerParams = {method: 'POST', headers: { referer: 'unsafe-url' }, 'Content-Type': 'application/json', body:
      queryParams}
    const url = `ws_create_fichecom`;
    const data = await http(`${GlobalBaseUrl}/${url}`, 'json', headerParams,true);

    if (data.link) {
      dispatch(setSimulatorFileLink(data.link));
    } else {
      dispatch(successAlert('La fiche Commerciale a bien été envoyée par email.'));
    }
  } catch (err) {
    dispatch(errorAlert('Erreur lors de la génération de la fiche Commerciale.'));
  } finally {
    dispatch(setFileIsLoading(false));
  }
};

const parseParams = (params) => {
  const queryParams = new URLSearchParams(params);
  queryParams.delete("simu_log");
  Object.entries(params.simu_log).forEach((data) => {
    queryParams.append(`simu_log[${data[0]}]`, data[1]);
  });

  return queryParams;
};
