/**
 * Return an Object with all data calculated
 *
 * @param {object} formData {
 *   {number} duration
 *   {number} amount
 *   {string} insuranceBorrower - value for insurance of borrower
 *   {string} insurancecoBorrower - value for insurance of coborrower
 *   {object} equipment - equipment associated to simulation
 * }
 * @param {object} equipmentLoan - data from API {
 *   {string} scale - modality of simulation
 *   {bool} postpone
 *   {object} typeScale
 *   {object} typeLoan
 *   {array} loanAmountStep
 *   {array} loanDurationStep
 * }
 * @param {number} ageInsurance
 * @param {number} ageCoInsurance
 */
export const calculateLoan = (
  formData,
  equipmentLoan,
  dataSimulator,
  ageInsurance,
  ageCoInsurance,
) => {
  const {
    duration,
    amount,
    insuranceBorrower,
    insuranceCoborrower,
    equipment,
  } = formData;
  const { scale } = equipmentLoan;
  const { postpone } = equipmentLoan;
  const { typeScale } = equipmentLoan;
  const { typeLoan } = equipmentLoan;
  const loanAmountSteps = equipmentLoan.loanAmountStep;
  const loanDurationSteps = equipmentLoan.loanDurationStep;
  const linkCoefValues = dataSimulator.linkCoefValue;
  const insuranceAmountRanges = dataSimulator.insuranceAmountRange;
  const insuranceDurationRanges = dataSimulator.insuranceDurationRange;
  const insuranceGridCells = dataSimulator.insuranceGridCell;
  const insuranceAgeRanges = dataSimulator.insuranceAgeRange;

  const typeLoanId = typeLoan.id;

  // Get the LinkCoefValue Object, determines which value is to be used for calculations and which value is to be displayed
  const linkCoefValue = getLinkCoefValue(amount, duration, typeLoanId, scale, linkCoefValues, loanAmountSteps, loanDurationSteps);
  const linkCoefValueNormal = getLinkCoefValue(amount, duration, typeLoanId, 'normal', linkCoefValues, loanAmountSteps, loanDurationSteps);
  let tnc = 0.0;
  let teg = 0.0;
  let tncDisplay = 0.0;
  let tegDisplay = 0.0;
  let tncNormal = null;

  switch (scale) {
    case 'normal':
      tnc = linkCoefValueNormal?.tnc || 0.0;
      teg = linkCoefValue?.teg || 0.0;
      tncDisplay = tnc;
      tegDisplay = teg;
      break;
    case 'compense':
      tnc = linkCoefValue?.tnc || 0.0;
      teg = linkCoefValue?.teg || 0.0;
      tncDisplay = tnc;
      tegDisplay = teg;
      tncNormal = linkCoefValueNormal?.tnc || 0.0;
      break;
    case 'gratuit':
    default:
      tncDisplay = linkCoefValueNormal?.tnc || 0.0;
      tegDisplay = linkCoefValueNormal?.teg || 0.0;
      tncNormal = linkCoefValueNormal?.tnc || 0.0;
      break;
  }

  // new calculate insurance coef
  const borrowersGridId = getInsuranceBorrower(insuranceBorrower, typeLoan);
  const coborrowersGridId = getInsuranceBorrower(insuranceCoborrower, typeLoan);

  const insuranceBorrowerCoef = retrieveInsuranceCoef(
    borrowersGridId,
    duration,
    amount,
    insuranceAmountRanges,
    insuranceDurationRanges,
    insuranceGridCells,
    ageInsurance,
    insuranceAgeRanges,
  );

  const insuranceCoborrowerCoef = retrieveInsuranceCoef(
    coborrowersGridId,
    duration,
    amount,
    insuranceAmountRanges,
    insuranceDurationRanges,
    insuranceGridCells,
    ageCoInsurance,
    insuranceAgeRanges,
  );

  const postponeMonths = (postpone ? 5 : 0);

  // Calculate loan details according to formula
  const dataCalc = {
    insuranceCoef: insuranceBorrowerCoef + insuranceCoborrowerCoef,
    postponeMonths,
    tnc,
    teg,
    duration,
    amount,
  };

  const results = calculateNormal(dataCalc);

  // Equipment data
  results.typeEquipmentId = equipment.id;
  results.equipmentName = equipment.equipmentName;
  if (scale === 'compense') {
    results.equipmentName += ' - compensé';
  }

  // Calculate insurance part
  results.loanTotalMonthly = roundFormat(insuranceBorrowerCoef * results.linearInst / 100.0, 2);
  results.loanTotalInsuranceOptional = roundFormat(results.loanTotalMonthly * duration, 2);
  results.coborrowerTotalMonthly = 0;
  results.insuranceBorrower = insuranceBorrower;
  results.insuranceCoborrower = insuranceCoborrower;
  if (coborrowersGridId) {
    results.coborrowerTotalMonthly = roundFormat(insuranceCoborrowerCoef * results.linearInst / 100.0, 2);
    results.coborrowerLoanTotalInsuranceOptional = roundFormat(results.coborrowerTotalMonthly * duration, 2);
  }

  // Calculate total installments
  results.monthly1InsLin = parseFloat(results.monthly1) + parseFloat(results.loanTotalMonthly) + parseFloat(results.coborrowerTotalMonthly);
  results.monthly1InsLin = roundFormat(results.monthly1InsLin, 2);

  // Deduction coeff
  if (scale === 'compense' || scale === 'gratuit') {
    let deductionCoef = 0.0;
    if (tnc < tncNormal) {
      deductionCoef = calculateDeductionCoef(tnc, tncNormal, postponeMonths, duration, amount, scale);
    }
    results.amountRetainedSeller = roundFormat(deductionCoef * amount, 2);
    results.retainedCoef = roundFormat(deductionCoef * 100, 2);
  }


  results.amountAdjust = amount;
  results.amountBorrower = roundFormat(parseFloat(results.amountAdjust) + parseFloat(results.creditCost), 2);
  if (tnc === 0.0 || teg === 0.0) { // adjust to make sure roundings will not give imprecise results
    results.amountBorrower = results.amountAdjust;
  }
  results.duration = parseInt(duration, 10);
  results.durationCreditContract = parseInt(duration, 10) + postponeMonths;
  results.firstDeadline = (postpone ? 180 : 30);
  results.dossierFees = 0;
  results.tncDisplay = tncDisplay;
  results.tegDisplay = tegDisplay;
  results.insuranceBorrowerCoef = insuranceBorrowerCoef;
  results.insuranceCoborrowerCoef = insuranceCoborrowerCoef;
  results.scale = scale;
  results.typeScaleId = typeScale.id;
  results.postpone = postpone;
  results.postponeMonths = postponeMonths;
  results.typeScaleLabel = typeScale.tslabel;

  const K = parseFloat(results.amountAdjust);
  const monthly1 = parseFloat(results.monthly1);
  const duration1 = parseInt(results.duration1, 10);
  let monthly2 = 0.0;
  let duration2 = 0;

  if (results.mensualite2) {
    monthly2 = parseFloat(results.mensualite2);
  }

  if (results.duree2) {
    duration2 = parseInt(results.duree2, 10);
  }

  let Ass = parseFloat(results.loanTotalMonthly);
  if (results.coborrowerTotalMonthly) {
    Ass = parseFloat(results.loanTotalMonthly) + parseFloat(results.coborrowerTotalMonthly);
  }

  const taeg = calculateTaeg(monthly1, duration1, monthly2, duration2, 0.0, K, postponeMonths);
  const taegass = calculateTaeg(monthly1, duration1, monthly2, duration2, Ass, K, postponeMonths);

  results.taea = taegass - taeg;

  return results;
};

/**
 * Get Possible Durations.
 *
 * @param {number} amount
 * @param {object} equipmentLoan - data from API {
 *   {string} scale - modality of simulation
 *   {bool} postpone
 *   {object} typeScale
 *   {object} typeLoan
 *   {array} loanAmountStep
 *   {array} loanDurationStep
 * }
 * @param dataSimulator - data from API
 * @param {number} ageInsuranceInMonth
 *
 * @returns {array}
 */
export const getPossibleDurations = (amount, equipmentLoan, dataSimulator, ageInsuranceInMonth) => {
  const { scale } = equipmentLoan;
  const { typeScale } = equipmentLoan;
  const { typeLoan } = equipmentLoan;
  const loanAmountSteps = equipmentLoan.loanAmountStep;
  const loanDurationSteps = equipmentLoan.loanDurationStep;
  const linkCoefValues = dataSimulator.linkCoefValue;
  const maxLoanInMonth = 972;
  let allowedDuration = [];

  const tsLimitMin = typeScale.tslimitmin;
  const tsLimitMax = typeScale.tslimitmax;

  let possibleDurationOpt = [12, 24, 36, 48, 60, 72, 84, 96, 108, 120, 132, 144];

  const typeLoanId = typeLoan.id;
  if (typeLoan) {
    possibleDurationOpt = typeLoan.tldurations.split(',');
  }

  const availableAmountStep = loanAmountSteps.find((loanAmount) => (
    amount
    && amount >= loanAmount.minAmount
    && amount <= loanAmount.maxAmount
    && loanAmount.typeLoan.id === typeLoanId
    && loanAmount.scale === scale
  ));

  const availableLinkCoefValues = linkCoefValues.filter((linkCoefValue) => (
    availableAmountStep?.id === linkCoefValue.loanAmountStep.id
  ));
  const availableLoanDurationId = availableLinkCoefValues.map((linkCoef) => linkCoef.loanDurationStep.id);

  const availableLoanDurationSteps = loanDurationSteps.filter((loanDurationStep) => (
    loanDurationStep.typeLoan.id === typeLoanId
    && loanDurationStep.scale === scale
    && availableLoanDurationId.includes(loanDurationStep.id)
  ));

  availableLoanDurationSteps.forEach((loanDuration) => {
    possibleDurationOpt.forEach((possibleDurationTmp) => {
      if (
        possibleDurationTmp >= loanDuration.minDuration
        && possibleDurationTmp <= loanDuration.maxDuration
        && possibleDurationTmp >= tsLimitMin
        && possibleDurationTmp <= tsLimitMax
        && ageInsuranceInMonth + Number(possibleDurationTmp) < maxLoanInMonth
      ) {
        allowedDuration.push(parseInt(possibleDurationTmp));
      }
    });
  });

  allowedDuration = removeDuplicates(allowedDuration);
  allowedDuration.sort(function(a, b) {
    return a - b;
  });

  return allowedDuration;
};

/**
 * Format a string number to two decimals
 *
 * @param {number} nb
 *
 * @returns {string}
 */
export const numberFormat = (nb) => {
  if (nb === undefined || nb === null) {
    return '';
  }

  nb = Math.round(nb * 100) / 100;
  const nbParts = nb.toString().split('.');
  let decimals = '00';

  if (nbParts.length > 1) {
    decimals = nbParts[1];
    switch (decimals.length) {
      case 0:
        decimals = '00';
        break;
      case 1:
        decimals += '0';
        break;
      case 2:
        break;
      default:
        decimals = decimals.substring(0, 2);
        break;
    }
  }

  let number = nbParts[0];
  number = number.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

  return (`${number},${decimals}`);
};

// Private methodes

const removeDuplicates = (array) => array.filter((a, b) => array.indexOf(a) === b);

/**
 * Get Insurance id for borrowers
 *
 * @param {string} insuranceBorrower
 * @param {object} typeLoan
 *
 * @returns {number}
 */
const getInsuranceBorrower = (insuranceBorrower, typeLoan) => {
  let borrowersGridId = 0;

  switch (insuranceBorrower) {
    case 'D':
      borrowersGridId = typeLoan?.d?.id ?? typeLoan?.dimId?.id;
      break;
    case 'DIM':
      borrowersGridId = typeLoan?.dimId?.id;
      break;
    case 'DIMC':
      borrowersGridId = typeLoan?.dimc?.id;
      break;
    case 'Aucune':
    default:
      break;
  }

  return borrowersGridId;
};

/**
 * Calculate taeg.
 *
 * @param {number} monthly1
 * @param {number} duration1
 * @param {number} monthly2
 * @param {number} duration2
 * @param {number} Ass
 * @param {number} K
 * @param {number} postponeMonths
 *
 * @returns {number}
 */
const calculateTaeg = (monthly1, duration1, monthly2, duration2, Ass, K, postponeMonths) => {
  let taegMin = 0.0;
  let taegMax = 30.0;

  for (let i = 0; i < 20; i++) {
    const Ktmp = capitalTaea(monthly1, duration1, monthly2, duration2, Ass, (taegMin + taegMax) / 2.0, postponeMonths);

    if (Ktmp > K) {
      taegMin = (taegMin + taegMax) / 2.0;
    }

    if (Ktmp < K) {
      taegMax = (taegMin + taegMax) / 2.0;
    }

    if (Ktmp === K) {
      return (taegMin + taegMax) / 2.0;
    }
  }

  return (taegMin + taegMax) / 2.0;
};

/**
 * Calculate taea.
 *
 * @param {number} monthly1
 * @param {number} duration1
 * @param {number} monthly2
 * @param {number} duration2
 * @param {number} Ass
 * @param {number} taeg
 * @param {number} postponeMonths
 *
 * @returns {number}
 */
const capitalTaea = (monthly1, duration1, monthly2, duration2, Ass, taeg, postponeMonths) => {
  let K = 0.0;

  for (let i = 1 + postponeMonths; i <= duration1 + postponeMonths; i++) {
    K += (monthly1 + Ass) / Math.pow((1.0 + taeg / 100.0), parseFloat(i) / 12.0);
  }

  for (let i = duration1 + 1 + postponeMonths; i <= duration1 + duration2 + postponeMonths; i++) {
    K += (monthly2 + Ass) / Math.pow((1.0 + taeg / 100.0), parseFloat(i) / 12.0);
  }

  return K;
};

/**
 * Calculate monthly, linearInst and creditCost from data with linear scale
 *
 * @param {object} dataCalc
 */
const calculateNormal = (dataCalc) => {
  const results = {};

  const adjAmount = dataCalc.amount * Math.pow(1.0 + dataCalc.tnc / 1200.0, dataCalc.postponeMonths);
  const monthly1 = PMT(dataCalc.tnc / 1200.0, dataCalc.duration, adjAmount);

  results.monthly1 = roundFormat(monthly1, 2);
  results.linearInst = results.monthly1;
  results.duration1 = dataCalc.duration;

  results.creditCost = 0.0;
  if (dataCalc.tnc !== 0.0) {
    results.creditCost = results.monthly1 * dataCalc.duration - dataCalc.amount;
  }

  return results;
};

/**
 * Calculate PMT.
 *
 * @param {number} rate
 * @param {number} duration
 * @param {number} amount
 *
 * @returns {number}
 */
const PMT = (rate, duration, amount) => {
  let pmt = amount / duration;

  if (rate !== 0.0) {
    pmt = amount * rate / (1 - Math.pow((1 + rate), -1 * duration));
  }

  return pmt;
};

/**
 * Calculate PV.
 *
 * @param {number} rate
 * @param {number} nbMonths
 * @param {number} inst
 *
 * @returns {number}
 */
const PV = (rate, nbMonths, inst) => inst * (1.0 - Math.pow((1.0 + rate), -1.0 * nbMonths)) / rate;

/**
 * Format number.
 *
 * @param {number} number
 * @param {number} roundDecimal
 *
 * @returns {number}
 */
const roundFormat = (number, roundDecimal) => Math.ceil(number * Math.pow(10.0, roundDecimal)) / Math.pow(10.0, roundDecimal);

/**
 * Get LoanDurationStep defined by duration, scale and TypeLoanId.
 *
 * @param {number} duration
 * @param {string} scale
 * @param {string} typeLoanId
 * @param {array} loanDurationSteps
 *
 * @returns loanDurationStep|undefined
 */
const getLoanDurationSteps = (duration, scale, typeLoanId, loanDurationSteps) => loanDurationSteps.find((loanDurationStep) => (
  loanDurationStep.typeLoan.id === typeLoanId
  && loanDurationStep.scale === scale
  && parseInt(loanDurationStep.minDuration, 10) <= parseInt(duration, 10)
  && parseInt(loanDurationStep.maxDuration, 10) >= parseInt(duration, 10)
));

/**
 * Get getLoanAmountStep defined by amount, scale and TypeLoanId.
 *
 * @param {number} amount
 * @param {string} scale
 * @param {string} typeLoanId
 * @param {array} loanAmountSteps
 *
 * @returns loanAmountStep|undefined
 */
const getLoanAmountSteps = (amount, scale, typeLoanId, loanAmountSteps) => loanAmountSteps.find((loanAmountStep) => (
  loanAmountStep.typeLoan.id === typeLoanId
  && loanAmountStep.scale === scale
  && loanAmountStep.minAmount <= amount
  && loanAmountStep.maxAmount >= amount
));

/**
 * Get LinkCoefValue from LoanAmountStep and LoanDurationStep
 *
 * @param {number} amount
 * @param {number} duration
 * @param {string} typeLoanId
 * @param {string} scale
 * @param {array} linkCoefValues
 * @param {array} loanAmountSteps
 * @param {array} loanDurationSteps
 *
 * @returns LinkCoefValue|undefined
 */
const getLinkCoefValue = (
  amount,
  duration,
  typeLoanId,
  scale,
  linkCoefValues,
  loanAmountSteps,
  loanDurationSteps,
) => {
  const loanDuration = getLoanDurationSteps(duration, scale, typeLoanId, loanDurationSteps);
  const loanAmount = getLoanAmountSteps(amount, scale, typeLoanId, loanAmountSteps);

  return linkCoefValues.find((linkCoefValue) => (
    loanAmount?.id === linkCoefValue.loanAmountStep.id
    && loanDuration?.id === linkCoefValue.loanDurationStep.id
  ));
};

/**
 * Get insuranceAmountRange Object
 *
 * @param {number} borrowersGridId
 * @param {number} duration
 * @param {number} amount
 * @param {array} insuranceDurationRanges
 * @param {array} insuranceAmountRanges
 * @param {array} insuranceGridCells
 * @param {object} insuranceAgeRange
 *
 * @returns insuranceAmountRange|undefined
 */
const retrieveInsuranceAmountRange = (
  borrowersGridId,
  duration,
  amount,
  insuranceAmountRanges,
  insuranceDurationRanges,
  insuranceGridCells,
  insuranceAgeRange,
) => {
  let result = null;

  insuranceAmountRanges.find((insuranceAmountRange) => {
    if (
      insuranceAmountRange.iaGrid.id === borrowersGridId
      && parseFloat(insuranceAmountRange.iaMin) <= parseFloat(amount)
      && parseFloat(insuranceAmountRange.iaMax) >= parseFloat(amount)
    ) {
      result = retrieveInsuranceDurationRange(
        borrowersGridId,
        duration,
        insuranceAmountRange,
        insuranceDurationRanges,
        insuranceGridCells,
        insuranceAgeRange,
      );

      return result;
    }

    return false;
  });

  return result;
};

/**
 * Get insuranceDurationRange object
 *
 * @param {number} borrowersGridId
 * @param {number} duration
 * @param {array} insuranceDurationRanges
 * @param {object} insuranceAmountRange
 * @param {array} insuranceGridCells
 * @param {object} insuranceAgeRange
 *
 * @returns insuranceDurationRange|undefined
 */
const retrieveInsuranceDurationRange = (
  borrowersGridId,
  duration,
  insuranceAmountRange,
  insuranceDurationRanges,
  insuranceGridCells,
  insuranceAgeRange,
) => {
  let result = null;

  insuranceDurationRanges.find((insuranceDurationRange) => {
    if (
      insuranceDurationRange.idGrid.id === borrowersGridId
      && parseInt(insuranceDurationRange.idMin, 10) <= parseInt(duration, 10)
      && parseInt(insuranceDurationRange.idMax, 10) >= parseInt(duration, 10)
    ) {
      result = retrieveInsuranceGridCell(
        insuranceAmountRange,
        insuranceDurationRange,
        insuranceGridCells,
        insuranceAgeRange,
      );

      return result;
    }

    return false;
  });

  return result;
};

/**
 * Get insuranceGridCell Object
 * @param {object} insuranceDurationRange
 * @param {object} insuranceAmountRange
 * @param {array} insuranceGridCells
 * @param {object} insuranceAgeRange
 *
 * @returns insuranceGridCell|undefined
 */
const retrieveInsuranceGridCell = (
  insuranceAmountRange,
  insuranceDurationRange,
  insuranceGridCells,
  insuranceAgeRange,
) => {
  let result = null;

  insuranceGridCells.find((insuranceGridCell) => {
    if (
      insuranceGridCell.amountrange.id === insuranceAmountRange.id
      && insuranceGridCell.durationrange.id === insuranceDurationRange.id
      && insuranceGridCell.ageRange.id === insuranceAgeRange.id
    ) {
      result = insuranceGridCell;

      return true;
    }
    return false;
  });

  return result;
};

/**
 * Get insuranceAgeRange Object
 * @param {number} borrowersGridId
 * @param {number} duration
 * @param {number} amount
 * @param {array} insuranceAmountRanges
 * @param {array} insuranceDurationRanges
 * @param {array} insuranceGridCells
 * @param {number} ageInsurance
 * @param {array} insuranceAgeRanges
 *
 * @returns insuranceAgeRanges|undefined
 */
const retrieveInsuranceAgeRanges = (
  borrowersGridId,
  duration,
  amount,
  insuranceAmountRanges,
  insuranceDurationRanges,
  insuranceGridCells,
  ageInsurance,
  insuranceAgeRanges,
) => {
  const ageInMonth = ageInsurance * 12;
  let result = null;

  // If age insurance is in iaMin and iaMax
  insuranceAgeRanges.find(insuranceAgeRangeCurrent => {
    if (insuranceAgeRangeCurrent.iaMin <= ageInMonth
      && insuranceAgeRangeCurrent.iaMax >= ageInMonth
      && insuranceAgeRangeCurrent.iaGrid.id === borrowersGridId
    ) {
      result = retrieveInsuranceAmountRange(
        borrowersGridId,
        duration,
        amount,
        insuranceAmountRanges,
        insuranceDurationRanges,
        insuranceGridCells,
        insuranceAgeRangeCurrent,
      );

      return result;
    }

    return false;
  });

  // If age insurance have not age data
  if (result === null) {
    insuranceAgeRanges.find(insuranceAgeRangeCurrent => {
      if (insuranceAgeRangeCurrent.iaGrid.id === borrowersGridId
      && insuranceAgeRangeCurrent.iaMin === undefined
      && insuranceAgeRangeCurrent.iaMax === undefined) {
        result = retrieveInsuranceAmountRange(
          borrowersGridId,
          duration,
          amount,
          insuranceAmountRanges,
          insuranceDurationRanges,
          insuranceGridCells,
          insuranceAgeRangeCurrent,
        );

        return result;
      }

      return false
    });
  }

  return result;
};

/**
 * Get Insurance coef
 *
 * @param {number} borrowersGridId
 * @param {number} duration
 * @param {number} amount
 * @param {array} insuranceAmountRanges
 * @param {array} insuranceDurationRanges
 * @param {array} insuranceGridCells
 * @param {number} ageInsurance
 * @param {array} insuranceAgeRanges
 *
 * @returns {number}
 */
const retrieveInsuranceCoef = (
  borrowersGridId,
  duration,
  amount,
  insuranceAmountRanges,
  insuranceDurationRanges,
  insuranceGridCells,
  ageInsurance,
  insuranceAgeRanges,
) => {
  if (borrowersGridId === 0) {
    return 0.0;
  }

  const insuranceData = retrieveInsuranceAgeRanges(
    borrowersGridId,
    duration,
    amount,
    insuranceAmountRanges,
    insuranceDurationRanges,
    insuranceGridCells,
    ageInsurance,
    insuranceAgeRanges,
    );

  if (insuranceData) {
    return parseFloat(insuranceData.inscoef);
  }

  return 0.0;
};

/**
 * Calculate Deduction Coef
 *
 * @param {number} ptnc
 * @param {number} ptncNormal
 * @param {number} postponeMonths
 * @param {number} duration
 * @param {number} amount
 * @param {string} scale
 *
 * @returns {number}
 */
const calculateDeductionCoef = (ptnc, ptncNormal, postponeMonths, duration, amount, scale) => {
  let pv;
  let deductionCoef;
  ptnc /= 100.0;
  ptncNormal /= 100.0;
  const pmt = PMT(ptnc / 12.0, duration, amount * Math.pow((1 + ptnc / 12.0), postponeMonths));

  if (scale === 'compense') {
    pv = PV(ptncNormal / 12.0, duration, pmt);
    deductionCoef = (amount * Math.pow((1 + ptncNormal / 12.0), postponeMonths) - Math.floor(pv)) / amount;
  } else {
    pv = PV(ptncNormal / 12.0, duration, (pmt / Math.pow((1 + ptncNormal / 12.0), postponeMonths)));
    deductionCoef = 1.0 - Math.floor(pv) / amount;
  }

  return deductionCoef;
};

export const getMonth = (dateString) => {
  const currentDate = new Date();
  const date = getBirthday(dateString);
  const monthDiff = currentDate.getMonth() - date.getMonth();
  const yearDiff = currentDate.getFullYear() - date.getFullYear();
  let monthDiffSum = monthDiff + yearDiff * 12;

  if(currentDate.getDay() === date.getDay() || (currentDate.getDay() < date.getDay() && currentDate.getDate() < date.getDate())) {
    monthDiffSum--;
  }

  return monthDiffSum;
}

export const getMonthSimulator = (dateString) => {
  const currentDate = new Date();
  const date = getBirthday(dateString);
  const monthDiff = currentDate.getMonth() - date.getMonth();
  const yearDiff = currentDate.getFullYear() - date.getFullYear();
  let monthDiffSum = monthDiff + yearDiff * 12;

  if((currentDate < date)){//future
    if (currentDate.getDate() > date.getDate()){
      monthDiffSum--;
    }
  }

  if((currentDate > date)){//past
    if (currentDate.getDate() < date.getDate()){
      monthDiffSum--;
    }
  }

  return monthDiffSum;
}

export const getBirthday = (date) => {
  const day = date.substring(0, 2);
  const month = date.substring(2, 4);
  const year = date.substring(4);

  return new Date(year, month - 1, day);
}
